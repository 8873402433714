import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextField,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { ColorMultiPicker } from '../../../components/color-utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilterData, setFilterData } from 'src/redux/filter';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import lodash from 'lodash'
// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];
export const FILTER_GENDER_OPTIONS = ['Men', 'Women', 'Kids'];
export const FILTER_CATEGORY_OPTIONS = ['All', 'Shose', 'Apparel', 'Accessories'];
export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];
export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' },
];
export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

// ----------------------------------------------------------------------



export default function ActivityFilterSideBar({ openFilter, onOpenFilter, onCloseFilter }) {
  const filter = useSelector(state => state.filter)
  const dispatch = useDispatch()
  return (

    <>

      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Types
              </Typography>
              <FormGroup>
                {["post", "comment", "chat"].map((item) => (
                  <FormControlLabel key={item} control={<Checkbox />} checked={filter.activityTypes.includes(item)} onChange={e => {
                    if (filter.activityTypes.includes(item)) {
                      dispatch(setFilterData({ ...filter, activityTypes: filter.roles.filter(x => x != item) }))
                    } else {
                      dispatch(setFilterData({ ...filter, activityTypes: [...filter.roles, item] }))
                    }
                  }} label={item} />
                ))}
              </FormGroup>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Date Range
              </Typography>
              <DatePicker
                label="Start Date"
                sx={{ marginTop: "10px" }}
                maxDate={moment()}
                value={filter?.dateRange[0]}
                onChange={(newValue) => {
                  let ranges=lodash.cloneDeep(filter.dateRange)
                  ranges[0]=newValue
                  dispatch(setFilterData({ ...filter, dateRange: ranges }))
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography sx={{ textAlign: "center" }}>-</Typography>
              <DatePicker
                label="End Date"
                maxDate={moment()}
                value={filter?.dateRange[1]}
                onChange={(newValue) => {
                  let ranges=lodash.cloneDeep(filter.dateRange)
                  ranges[1]=newValue
                  dispatch(setFilterData({ ...filter, dateRange: ranges }))

                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={() => {
              dispatch(clearFilterData())
            }}
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>

    </>

  );
}
