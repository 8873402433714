import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useDispatch } from 'react-redux';
import { setLoader } from 'src/redux/loader';
import hit from 'src/services/manager';
import { setAuthorize } from 'src/redux/auth';
import { setToast } from 'src/redux/toast';
import SocialButton from 'src/components/SocialButton';
import { firebaseAuth } from "../../../utils/firebaseInit";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

// ----------------------------------------------------------------------

export default function LoginForm() {

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch()
  const [rem, setRem] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const navigate = useNavigate()
  const handleClick = async (email, password, type = "normal") => {
    try {
      dispatch(setLoader(true))
      let res = await hit("/auth/login-admin", "post", { email, password, type })
      if (!res.err) {
        localStorage.setItem("@token", res.data?.tokens?.access?.token)
        localStorage.setItem("@remember", rem ? "yes" : "no")
        navigate('/dashboard')
        dispatch(setAuthorize(true))
        dispatch(setToast({ message: "Login Successfully", severity: "success", show: true }))
      } else {
        dispatch(setToast({ message: res.msg, severity: "error", show: true }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))
    }
  };

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      signInWithPopup(firebaseAuth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          let f_name = user?.displayName?.split(" ")[0] ?? ""
          let l_name = user?.displayName?.split(" ")[1] ?? ""
          if (user?.email) {
            handleClick(user?.email, token, "social",)
          }
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // showToast(error.message, "error")
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });

    } catch (err) {

    } finally {

    }

  }
  const appleLogin = async () => {
    try {
      const provider = new OAuthProvider("apple.com");
      provider.addScope('email');
      provider.addScope('name');
      signInWithPopup(firebaseAuth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = OAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const idToken = credential.idToken;
          // The signed-in user info.
          const user = result.user;
          let f_name = user?.displayName?.split(" ")[0] ?? ""
          let l_name = user?.displayName?.split(" ")[1] ?? ""
          if (user?.email) {
            handleClick(user?.email, token, "apple",)
          }
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // showToast(error.message, "error")
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });

    } catch (err) {

    } finally {

    }

  }



  return (
    <>
      <Stack spacing={3}>
        <TextField value={email} onChange={e => {
          setEmail(e.target.value)
        }} name="email" label="Email address" />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={e => {
            setPassword(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
        <Checkbox onChange={(e) => {
          setRem(e.target.checked)
        }} value={rem} name="remember" />
        <Link color={"ButtonText"} variant="subtitle2" underline="hover">
          Remember me
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => handleClick(email, password)}>
        Login
      </LoadingButton>
      <Box sx={{ display: "flex", alignItems: "center",justifyContent:'center',marginTop:3,gap:1 }}>
        <SocialButton title={"Login with Google"} onPress={() => {
          googleSignIn()
        }} img={"assets/login/google.png"} />
        <SocialButton onPress={() => {
          appleLogin()
        }} img={"assets/login/apple.png"} title={"Login with Apple"} textStyle={{ color: "#F5BA16" }} style={{ background: "#FEBE2D17", }} />
      </Box>
    </>
  );
}
