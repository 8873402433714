import { Navigate, Outlet, useNavigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import SettingPage from './pages/SettingPage';
import SupportsPage from './pages/SupportPage';
import BirthdayPage from './pages/BirthdayPage';
import UserActivityPage from './pages/UserActivityPage';

// ----------------------------------------------------------------------

export default function Router() {

  const navigate = useNavigate()
  const auth = useSelector(state => state.auth)
  useEffect(() => {
    const token = localStorage.getItem('@token')
    if (auth?.authorize || token) {
      if (auth?.user?.role != undefined) {
        if (auth?.user?.role == "staff" || auth?.user?.role == "admin") {
          // navigate("/dashboard", { replace: true })
          <Outlet/>
        } else {
          navigate("/dashboard/products", { replace: true })
        }
      }
    } else {
      navigate("/login", { replace: true })
    }
  }, [auth])
  const routes = useRoutes([
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'settings', element: <SettingPage /> },
        { path: 'supports', element: <SupportsPage /> },
        { path: 'birth', element: <BirthdayPage /> },
        { path: 'activity/:userId', element: <UserActivityPage /> },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
