import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import { image_base_url } from 'src/services/endpoints';
import { useLocation } from 'react-router-dom';
import { getSupportsThunk } from 'src/redux/support';
import { setLoader } from 'src/redux/loader';
import hit from 'src/services/manager';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { setToast } from 'src/redux/toast';
// ----------------------------------------------------------------------

//0 : request
//1 : accepted
//2 :rejected
//3 : pickedup
//4 : delievered
//5 : received
//6 : Canceled
const orderTypes = {
    "0": "Requested",
    "1": "Accepted",
    "2": "Rejected",
    "3": "Picked Up",
    "4": "Delieverd",
    "5": "Received",
    "6": "Cancelled"
}
const listData = [
    { value: 0, label: "Requested" },
    { label: "Accepted", value: 1 },
    { label: "Rejected", value: 2 },
    { label: "Picked Up", value: 3 },
    { label: "Delieverd", value: 4 },
    { label: "Received", value: 5 },
    { label: "Cancelled", value: 6 },
    { label: "All", value: 10 }

]

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    // { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'message', label: 'Message', alignRight: false },
    { id: 'response', label: 'Response', alignRight: false },
    { id: 'action', label: "Action", alignRight: false },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user?.to?.address?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function SupportsPage() {
    const writes = useSelector(state => state.navs)?.write
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('firstName');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [selectedStatus, setSelectedStatus] = useState(10)
    const loginUser = useSelector(state => state.auth)?.user
    const supports = useSelector(state => state.supports)?.list
    const [selectedItem, setSelectedItem] = useState(null)

    const location = useLocation()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSupportsThunk())
    }, [location])

    useEffect(() => {
        if (open) {
            setMessage("")
        }
    }, [open])

    const handleCloseMenu = () => {
        setOpen(null);
    };
    useEffect(() => {
        console.log(supports)
    }, [supports])
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = supports?.map((n) => n?.firstName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const [message, setMessage] = useState("")


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - supports?.length) : 0;

    const deleteSupport = async (id) => {
        try {
            dispatch(setLoader(true))
            let res = await hit("/supports/" + id, "delete")
            if (!res.err) {
                dispatch(setToast({ show: true, message: "deleted successfully!", severity: "success" }))

                dispatch(getSupportsThunk())
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    const respondMessage = async () => {
        try {
            dispatch(setLoader(true))
            let res = await hit("/supports/respond", "post", { id: selectedId, respond: message })
            if (!res.err) {
                dispatch(getSupportsThunk())
                dispatch(setToast({ show: true, message: "responded successfully!", severity: "success" }))

            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }
    const [selectedId, setSelectedId] = useState("")


    return (
        <>
            <Helmet>
                <title>Contact Us | CultureCode</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Contact Us
                    </Typography>
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={supports.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}

                                />
                                <TableBody>
                                    {supports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const { user, message, tags, _id, phone, email, name, respond } = row;
                                        const selectedUser = selected.indexOf(_id) !== -1;
                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                <TableCell padding="checkbox" sx={{ paddingLeft: "5px" }}>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {user?.firstName ? user?.firstName : name}
                                                </TableCell>
                                                {/* <TableCell component="th" scope="row" padding="none">
                                                    {phone}
                                                </TableCell> */}
                                                <TableCell onClick={() => {
                                                    setSelectedId(_id)
                                                    setOpen(true)
                                                }} component="th" scope="row" padding="none">
                                                    {user?.email ? user?.email : email}
                                                </TableCell>
                                                <TableCell align="left">{message}</TableCell>
                                                <TableCell align="left">{respond}</TableCell>
                                                <TableCell>
                                                    <Button onClick={() => {
                                                        if (loginUser?.role == "admin") {
                                                            setSelectedId(_id)
                                                            setOpen(true)
                                                        } else if (loginUser?.role == "staff") {
                                                            if (writes?.includes("supports")) {
                                                                setSelectedId(_id)
                                                                setOpen(true)
                                                            } else {
                                                                dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))
                                                            }
                                                        }

                                                    }} sx={{ margin: "5px" }} variant='contained' color='info' >Respond</Button>
                                                    <Button onClick={() => {
                                                        if (loginUser?.role == "admin") {
                                                            deleteSupport(_id)
                                                        } else if (loginUser?.role == "staff") {
                                                            if (writes?.includes("supports")) {
                                                                deleteSupport(_id)
                                                            } else {
                                                                dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))
                                                            }
                                                        }

                                                    }} sx={{ margin: "5px" }} variant='contained' color='error' >Delete</Button>

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {/* 
                                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={supports.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            <Dialog open={open} onClose={() => {
                setOpen(false)
            }}>
                <DialogTitle>Respond</DialogTitle>
                <DialogContent>
                    <DialogContentText>Type a response to the messsage from user.</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        // sx={{minHeight:"100px"}}
                        id="name"
                        label="Message"
                        // type="email"
                        multiline
                        value={message}
                        rows={4}
                        onChange={t => {
                            // alert(t)

                            setMessage(t.target.value)
                            // setMessage(t.nativeEvent.va)
                        }}
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false) }}>Cancel</Button>
                    <Button onClick={() => {
                        if (message?.trim()?.length == 0) {
                            dispatch(setToast({ show: true, message: "add response!", severity: "error" }))
                            return
                        }
                        respondMessage()
                        setOpen(false)
                    }}>Send</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
