import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { image_base_url } from 'src/services/endpoints';

export default function RankingsModal({ title, description, open, setOpen, selectedGroupId, onOk, onCancel }) {
    const scores = useSelector(state => state.scores).list
    const teams = useSelector(state => state.teams)?.list
    const [data, setData] = useState([])
    useEffect(() => {
        if (selectedGroupId && open) {
            let team = teams.find(x => x._id == selectedGroupId)

            if (team) {
                let d = []
                let users = team.members
                let admins=team.admins
                let sc = scores.filter(x => x.groups?.includes(team._id))
                for (let u of users) {
                    d.push({ ...u, points: sc.filter(x => x?.user?._id == u?._id).map(x => x?.point).reduce((a, b) => a + b, 0),isAdmin:admins?.map(x=>x?._id)?.includes(u?._id) })
                }
                d.sort((a, b) => b.points - a.points)
                setData(d)
            }
        }
    }, [open, selectedGroupId, scores, teams])
    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {data?.map((x, i) => {
                    return (
                    <><div style={{ display: "flex", minWidth: "400px",alignItems:"center", marginTop: "10px" }}>
                        <Typography>{i + 1}.</Typography>
                        <Stack direction="row" alignItems="center" flex={1} marginLeft={"5px"} spacing={2}>
                            <Avatar alt={x?.firstName} src={image_base_url + x?.profilePic} />
                            <Typography variant="subtitle2" noWrap>
                                {x?.firstName}{x?.firstName==""?x?.email?.split("@")[0]:""}{x?.isAdmin?" (Admin)":""}
                            </Typography>
                        </Stack>
                        <Typography>{x?.points}</Typography>
                    </div>
                    <Divider sx={{marginTop:"5px"}}/>
                    </>
                    )
                })}
            </DialogContent>
        </Dialog>
    );
}