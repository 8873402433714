// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getToken, getMessaging } from 'firebase/messaging';
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyABlKPY_5MMLPCJCt05aemeiBi_B05dPtE",
    authDomain: "culturecode-5fcb9.firebaseapp.com",
    projectId: "culturecode-5fcb9",
    storageBucket: "culturecode-5fcb9.appspot.com",
    messagingSenderId: "198986240651",
    appId: "1:198986240651:web:d38521959288d29eccfa8e",
    measurementId: "G-P2HPNL372W"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
const firebaseDb = getFirestore(firebaseApp);
const firebaseAuth = getAuth(firebaseApp)
const messaging = getMessaging(firebaseApp);
const firebaseStorage=getStorage(firebaseApp)

const getOrRegisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        return window.navigator.serviceWorker
            .getRegistration('/')
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                    scope: '/',
                });
            });
    }
    throw new Error('The browser doesn`t support service worker.');
};

const getFirebaseToken = () =>
    getOrRegisterServiceWorker()
        .then((serviceWorkerRegistration) =>
            getToken(messaging, { vapidKey: "BAPA89gs-fwyNYr0C-4_q6g0pSIeJk8Fy-LSO4jhet7SawTSLORVzKhPhg-Xd5C9Tn7OYVNTLIHZw8qoWh3ytg4", serviceWorkerRegistration }));



export { firebaseApp, firebaseDb, firebaseAnalytics,firebaseStorage, firebaseAuth, messaging, getOrRegisterServiceWorker, getFirebaseToken }