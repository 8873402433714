import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { ColorMultiPicker } from '../../../components/color-utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilterData, setFilterData } from 'src/redux/filter';
import lodash from 'lodash'
// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];
export const FILTER_GENDER_OPTIONS = ['Men', 'Women', 'Kids'];
export const FILTER_CATEGORY_OPTIONS = ['All', 'Shose', 'Apparel', 'Accessories'];
export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];
export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' },
];
export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

// ----------------------------------------------------------------------

ShopFilterSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
};

export default function ShopFilterSidebar({ openFilter, onOpenFilter, onCloseFilter }) {
  const filter = useSelector(state => state.filter)
  const dispatch = useDispatch()
  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                User Role
              </Typography>
              <FormGroup>
                {["user", "parent", "staff"].map((item) => (
                  <FormControlLabel key={item} control={<Checkbox />} checked={filter.roles.includes(item)} onChange={e => {
                    if (filter.roles.includes(item)) {
                      dispatch(setFilterData({ ...filter, roles: filter.roles.filter(x => x != item) }))
                    } else {
                      dispatch(setFilterData({ ...filter, roles: [...filter.roles, item] }))
                    }
                  }} label={lodash.capitalize(item)} />
                ))}
              </FormGroup>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                User Type
              </Typography>
              <FormGroup>
                {["student", "business"].map((item) => (
                  <FormControlLabel key={item} control={<Checkbox />} checked={filter.types.includes(item)} onChange={e => {
                    if (filter.types.includes(item)) {
                      dispatch(setFilterData({ ...filter, types: filter.types.filter(x => x != item) }))
                    } else {
                      dispatch(setFilterData({ ...filter, types: [...filter.types, item] }))
                    }
                  }} label={lodash.capitalize(item) } />
                ))}
              </FormGroup>
            </div>
            {/* <div> */}
              {/* <Typography variant="subtitle1" gutterBottom>
                Grade
              </Typography>
              <FormGroup>
                {[
                  "Pre-K",
                  "Kindergarten",
                  "1st",
                  "2nd",
                  "3rd",
                  "4th",
                  "5th",
                  "6th",
                  "7th",
                  "8th",
                  "9th",
                  "10th",
                  "11th",
                  "12th",
                ].map((item) => (
                  <FormControlLabel onChange={e => {
                    if (filter.grades.includes(item)) {
                      dispatch(setFilterData({ ...filter, grades: filter.grades.filter(x => x != item) }))
                    } else {
                      dispatch(setFilterData({ ...filter, grades: [...filter.grades, item] }))
                    }
                  }} key={item} control={<Checkbox />} checked={filter.grades.includes(item)} label={item} />
                ))}
              </FormGroup>
            </div> */}
            {/* <div>
              <Typography variant="subtitle1" gutterBottom>
                Category
              </Typography>
              <RadioGroup>
                {FILTER_CATEGORY_OPTIONS.map((item) => (
                  <FormControlLabel key={item} value={item} control={<Radio />} label={item} />
                ))}
              </RadioGroup>
            </div> */}


          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={() => {
              dispatch(clearFilterData())
            }}
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
