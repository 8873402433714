import React, { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import { TextField, Button, Container, Stack, Box, Typography, Grid } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from 'react-redux';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { setMiscData } from 'src/redux/miscdata';
import { setToast } from 'src/redux/toast';
import UserPass from 'src/components/userAndPass.js';
import { useLocation } from 'react-router-dom';

// components
// mock

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function SettingPage() {

    const dispatch = useDispatch()
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)


    const submit = async () => {
        if (password.trim() == "") {
            dispatch(setToast({ show: true, message: "password is empty", severity: "error" }))
            return
        }
        if (password != confirmPassword) {
            dispatch(setToast({ show: true, message: "Password doesn't match", severity: "error" }))
            return
        }
        if (password?.length < 8) {
            dispatch(setToast({ show: true, message: "Password minimum length is 8", severity: "error" }))
            return
        }
        try {
            dispatch(setLoader(true))
            let res = await hit("/admins/password", "patch", { password })
            if (!res.err) {
                dispatch(setToast({ show: true, message: "Updated Successfully!", severity: "success" }))
            } else {
                dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }
    const [staffs, setStaffs] = React.useState([])
    const location = useLocation()
    useEffect(() => {
        getStaff()
    }, [location])

    const getStaff = async () => {
        try {
            dispatch(setLoader(true))
            let res = await hit("/admins/staffs", "get")
            if (!res.err) {
                setStaffs(res.data)
            } else {
                dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    const addStaff = async (data, selectedData) => {
        if (data?.password?.length < 8) {
            dispatch(setToast({ show: true, message: "Password minimum length is 8", severity: "error" }))
            return
        }
        try {
            dispatch(setLoader(true))
            let res = null
            if (selectedData) {
                res = await hit("/admins/staffs/" + selectedData?._id, "patch", data)
            } else {
                res = await hit("/admins/staffs", "post", data)
            }
            if (!res.err) {
                setOpen(false)
                setSelected(null)
                getStaff()
                dispatch(setToast({ show: true, message: selectedData ? "Updated Successfully" : "Added Successfully!", severity: "success" }))
            } else {
                dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    const deleteStaff = async (id) => {
        try {
            dispatch(setLoader(true))
            let res = await hit("/admins/staffs/" + id, "delete")
            if (!res.err) {
                dispatch(setToast({ show: true, message: "deleted Successfully!", severity: "success" }))
                getStaff()
            } else {
                dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    const [tokens, setTokens] = useState([])

    useEffect(() => {
    }, [location])



    return (
        <>
            <Helmet>
                <title> Dashboard: Sub | CultureCode </title>
            </Helmet>
            <Container>
                <Typography variant='h4'>Settings</Typography>
                <Typography mt={4}>Change Password</Typography>
                <Grid container spacing={2} mt={1}>
                    <Grid item sm={12} lg={4} >
                        <TextField value={password} onChange={e => {
                            setPassword(e.target.value)
                        }} name="tamount" label="New Password" sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <TextField value={confirmPassword} sx={{ width: "100%" }} onChange={e => {
                            setConfirmPassword(e.target.value)
                        }} name="cancellation" label="Confirm Password" />
                    </Grid>
                </Grid>
                <Box display="flex" mt={4}>
                    <Button onClick={() => {
                        submit()
                    }} variant='contained' >
                        Update Password
                    </Button>
                </Box>
                <Typography mt={4}>Staffs ({staffs?.length})</Typography>
                <Button sx={{ marginTop: "10px" }} onClick={() => {
                    setOpen(true)
                }} variant='contained' >
                    Add
                </Button>

                <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                    <Typography fontWeight={"600"} sx={{ width: "400px" }}>Email</Typography>
                    <Typography fontWeight={"600"} sx={{ width: "400px" }}>Password</Typography>
                </Box>
                {staffs?.map(x => {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                            <Typography sx={{ width: "400px" }}>{x?.email}</Typography>
                            <Typography sx={{ width: "400px" }}>{x?.accessPass}</Typography>
                            <Button onClick={() => {
                                setOpen(true)
                                setSelected(x)
                            }} color='success' sx={{ marginLeft: "10px" }}>Edit</Button>
                            <Button onClick={() => {
                                deleteStaff(x._id)
                            }} color='error' sx={{ marginLeft: "10px" }}>Delete</Button>
                        </Box>
                    )
                })}
                <UserPass open={open} setOpen={setOpen} onSubmit={(data, selected) => {
                    addStaff(data, selected)
                }} onCancel={() => {
                    setSelected(null)
                    setOpen(false)
                }} selected={selected} title={selected ? "Update Staff" : "Add Staff"} description={"Please select access level you want to provide to the staff."} />
            </Container>
        </>
    );
}
