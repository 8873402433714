import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    useTheme,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import { image_base_url } from 'src/services/endpoints';
import moment from 'moment'
import AskDialog from 'src/components/ask';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { setToast } from 'src/redux/toast';
import { getAllUsers } from 'src/redux/users';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUserDataThunk } from 'src/redux/auth';
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { firebaseDb } from 'src/utils/firebaseInit';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CSVLink } from 'react-csv';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'detail', label: 'Detail', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'createdat', label: "Created At", alignRight: true },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, userId) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (data) => {
            const { chat, message, type, createdAt, post } = data;
            let detail = ""
            if (type == "chat") {
                if (message?.type == "text") {
                    detail = `"${message?.text}"` + " message"
                } else if (message?.type == "image") {
                    detail = `${message?.image}` + " image"
                } else if (message?.type == "document") {
                    detail = `"${message?.image}"` + " document"
                } else if (message?.type == "wish") {
                    detail = `"${message?.text}"`
                }
                if (chat?.type == "single") {
                    detail = `${detail} sent to ${chat?.users?.filter(x => x?._id != userId)[0]?.firstName} in a group '${chat?.groupDetails?.name}'.`

                } else {
                    detail = `"${detail}" sent in a group '${chat?.group?.name}'.`
                }
            } else if (type == "comment") {
                detail = ``
                if (post.typeComment == 0) {
                    detail = `"${post?.txt}"`
                } else if (post.typeComment == 1) {
                    detail = `${image_base_url}${post?.txt}`
                } else if (post.typeComment == 2) {
                    detail = `${image_base_url}${post?.txt}`
                }
                let parentpost = post?.parentPostId
                if (parentpost?.postType1 == "theme") {
                    detail = detail + ` commented in a theme post " ${parentpost?.txt}".`
                } else if (parentpost?.postType1 == "poll") {
                    detail = detail + ` commented in a poll post " ${parentpost?.txt}".`
                } else if (parentpost?.postType1 == "quiz") {
                    detail = detail + ` commented in a quiz post " ${parentpost?.quizTitle}".`
                } else {
                    detail = detail + ` commented in a post " ${parentpost?.txt}".`
                }
            } else if (type == "post") {
                detail = ``
                if (post?.postType1 == "theme") {
                    detail = detail + `Posted a theme post " ${post?.txt}" in a groups ${post?.groups?.map(x => x.name)}.`
                } else if (post?.postType1 == "poll") {
                    detail = detail + `Posted a poll post " ${post?.txt}" in a groups ${post?.groups?.map(x => x.name)}.`
                } else if (post?.postType1 == "quiz") {
                    detail = detail + `Posted a quiz post " ${post?.quizTitle}" in a groups ${post?.groups?.map(x => x.name)}.`
                } else {
                    detail = detail + `Posted a post " ${post?.txt}" in a groups ${post?.groups?.map(x => x.name)}.`
                }
            }
            if (detail?.toLowerCase()?.includes(query?.toLowerCase())) {
                return true
            }
            return false
        });
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function UserActivityPage() {
    const [open, setOpen] = useState(null);
    const [csvData, setCsvData] = useState([])

    let { userId } = useParams();
    const [page, setPage] = useState(0);
    const dispatch = useDispatch()
    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('firstName');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const users = useSelector(state => state.users)?.list
    const filter = useSelector(state => state.filter)
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [posts, setPosts] = useState([])
    const [chats, setChats] = useState([])
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [selectedData, setSelectedData] = useState({
        status: 0,
        _id: null
    })

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const location = useLocation()

    useEffect(() => {
        dispatch(getAllUsers())
        getPostsAndCommentsAll(userId)
    }, [location])

    useEffect(() => {
        let q = query(collection(firebaseDb, "chats"), where("userIds", "array-contains", userId ?? ""))
        let unsub = onSnapshot(q,
            async (querySnapshot) => {
                let messages = []
                for (let snaps of querySnapshot.docs) {
                    let chatData = snaps.data()
                    let messagesC = await getDocs(collection(firebaseDb, "chats", snaps.id, "messages"))
                    for (let m of messagesC.docs) {
                        let d = m.data()
                        if (d?.user?._id == userId) {
                            messages.push({ chat: chatData, message: d, createdAt: d.createdAt * 1000, type: "chat" })
                        }
                    }
                }
                setChats(messages)

            },
            (error) => console.error(error),
        )
        return () => {
            unsub()
        }
    }, [location, userId])

    useEffect(() => {
        // console.log("Chats",chats)
        // const posts=posts.sort()
        let postsC = posts.map(x => ({ post: x, type: x?.postType, createdAt: moment(x?.createdAt).unix() * 1000 }))
        let newC = postsC.concat(chats)
        newC.sort((a, b) => b.createdAt - a.createdAt)
        setData(newC)
    }, [chats, posts])
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data?.map((n) => n?.firstName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };



    const deleteUser = async () => {
        try {
            dispatch(setLoader(true))
            let res = await hit("admins/users/" + selectedData?._id, "delete",)
            if (!res.err) {
                dispatch(getAllUsers())
            } else {
                dispatch(setToast({ show: true, message: res.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    const getPostsAndCommentsAll = async (userId) => {
        try {
            dispatch(setLoader(true))
            let res = await hit("admins/posts/user/" + userId, "get")
            if (!res.err) {
                setPosts(res.data)
            } else {
                dispatch(setToast({ show: true, message: res.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    useEffect(() => {
        let us = data
        if (filter.activityTypes?.length > 0) {
            us = us.filter(x => filter.activityTypes.includes(x?.type))
        }
        if (filter?.dateRange?.length > 0) {
            let start = filter?.dateRange[0]
            let end = filter?.dateRange[1]
            if (start) {
                us = us.filter(x => x?.createdAt > moment(start).unix() * 1000)
            }
            if (end) {
                us = us.filter(x => x?.createdAt < moment(end).unix() * 1000)

            }

        }
        setFilteredData(us)
    }, [data, filter])

    useEffect(() => {
        let newCSV = []
        newCSV.push(['Sno', 'Detail', 'Type',"Medias", 'Created At'])
        let i = 1
        for (let fd of filteredData) {
            const { chat, message, type, createdAt, post } = fd;
            let detail = ""
            let medias=""
            if (type == "chat") {
                if (message?.type == "text") {
                    detail = `${message?.text}` + " message"
                } else if (message?.type == "image") {
                    detail = `${message?.image}` + " image"
                } else if (message?.type == "document") {
                    detail = `${message?.image}` + " document"
                } else if (message?.type == "wish") {
                    detail = `${message?.text}`
                }
                if (chat?.type == "single") {
                    detail = `${detail} sent to ${chat?.users?.filter(x => x?._id != userId)[0]?.firstName} in a group ${chat?.groupDetails?.name}.`

                } else {
                    detail = `"${detail}" sent in a group ${chat?.group?.name}.`
                }
            } else if (type == "comment") {
                detail = ``
                if (post.typeComment == 0) {
                    detail = `${post?.txt}`
                } else if (post.typeComment == 1) {
                    detail = `${image_base_url}${post?.txt}`
                } else if (post.typeComment == 2) {
                    detail = `${image_base_url}${post?.txt}`
                }
                let parentpost = post?.parentPostId
                if (parentpost?.postType1 == "theme") {
                    detail = detail + ` commented in a theme post ${parentpost?.txt}.`
                } else if (parentpost?.postType1 == "poll") {
                    detail = detail + ` commented in a poll post ${parentpost?.txt}.`
                } else if (parentpost?.postType1 == "quiz") {
                    detail = detail + ` commented in a quiz post ${parentpost?.quizTitle}.`
                } else {
                    detail = detail + ` commented in a post ${parentpost?.txt}.`
                }
            } else if (type == "post") {
                detail = ``
                if (post?.postType1 == "theme") {
                    detail = detail + `Posted a theme post ${post?.txt} in a groups ${post?.groups?.map(x => x.name)}.`
                } else if (post?.postType1 == "poll") {
                    detail = detail + `Posted a poll post ${post?.txt} in a groups ${post?.groups?.map(x => x.name)}.`
                } else if (post?.postType1 == "quiz") {
                    detail = detail + `Posted a quiz post ${post?.quizTitle} in a groups ${post?.groups?.map(x => x.name)}.`
                } else {
                    detail = detail + `Posted a post ${post?.txt} in a groups ${post?.groups?.map(x => x.name)}.`
                }
                for(let v of post?.videos){
                    medias+=image_base_url+v+", "
                }
                for(let v of post?.photos){
                    medias+=image_base_url+v+", "
                }
                for(let v of post?.docs){
                    medias+=image_base_url+v+", "
                }
            }
            newCSV.push([i, String(detail).replace(/\n/g, ""), type,medias, moment(createdAt).format("MMM, DD, YYYY hh:mm a")])
            i++
        }
        console.log("CSVDAta", newCSV)
        setCsvData(newCSV)
    }, [filteredData])


    const user = useSelector(state => state.auth)?.user
    const navs = useSelector(state => state.navs)?.write

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData?.length) : 0;

    const filteredUsers = applySortFilter(filteredData, getComparator(order, orderBy), filterName, userId);

    const isNotFound = !filteredUsers.length && !!filterName;
    const navigate = useNavigate()
    const REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

    const Paragraph = ({ paragraph }) => {

        const paragraphArray = paragraph.split(' ');
        return <Box sx={{ flex: 1, maxWidth: "400px", wordWrap: "break-word" }}>

            {
                paragraphArray.map((word) => {
                    return word.match(REGEX) ? (
                        <>
                            <Typography component={"a"} target='_blank' sx={{ fontSize: "14px" }} color={"blue"} href={word}>{word}</Typography> {' '}
                        </>
                    ) : word + ' '
                })
            }
        </Box>;
    };
    const theme = useTheme()
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>

            <Helmet>
                <title> User Activity | CultureCode UI </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        User Activity ({location.state?.email})
                    </Typography>
                    <CSVLink style={{ color: "#0DB1D4" }} filename={`userActivity(${location.state?.email}).csv`} data={csvData}>Download CSV Data</CSVLink>

                </Stack>
                <Card>
                    <UserListToolbar numSelected={selected.length} placeholder="Search data..." show={true} typeFilter={"activity"} filterName={filterName} onFilterName={handleFilterByName} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={data.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const { chat, message, type, createdAt, post } = row;
                                        let detail = ""
                                        let photos = []
                                        let videos = []
                                        let docs = []
                                        if (type == "chat") {
                                            if (message?.type == "text") {
                                                detail = `"${message?.text}"` + " message"
                                            } else if (message?.type == "image") {
                                                detail = `${message?.image}` + " image"
                                            } else if (message?.type == "document") {
                                                detail = `"${message?.image}"` + " document"
                                            } else if (message?.type == "wish") {
                                                detail = `"${message?.text}"`
                                            }
                                            if (chat?.type == "single") {
                                                detail = `${detail} sent to ${chat?.users?.filter(x => x?._id != userId)[0]?.firstName} in a group '${chat?.groupDetails?.name}'.`

                                            } else {
                                                detail = `"${detail}" sent in a group '${chat?.group?.name}'.`
                                            }
                                        } else if (type == "comment") {
                                            detail = ``
                                            if (post.typeComment == 0) {
                                                detail = `"${post?.txt}"`
                                            } else if (post.typeComment == 1) {
                                                detail = `${image_base_url}${post?.txt}`
                                            } else if (post.typeComment == 2) {
                                                detail = `${image_base_url}${post?.txt}`
                                            }
                                            let parentpost = post?.parentPostId
                                            if (parentpost?.postType1 == "theme") {
                                                detail = detail + ` commented in a theme post " ${parentpost?.txt}".`
                                            } else if (parentpost?.postType1 == "poll") {
                                                detail = detail + ` commented in a poll post " ${parentpost?.txt}".`
                                            } else if (parentpost?.postType1 == "quiz") {
                                                detail = detail + ` commented in a quiz post " ${parentpost?.quizTitle}".`
                                            } else {
                                                detail = detail + ` commented in a post " ${parentpost?.txt}".`
                                            }
                                        } else if (type == "post") {
                                            detail = ``
                                            if (post?.postType1 == "theme") {
                                                detail = detail + `Posted a theme post " ${post?.txt}" in a groups ${post?.groups?.map(x => x.name)}.`
                                            } else if (post?.postType1 == "poll") {
                                                detail = detail + `Posted a poll post " ${post?.txt}" in a groups ${post?.groups?.map(x => x.name)}.`
                                            } else if (post?.postType1 == "quiz") {
                                                detail = detail + `Posted a quiz post " ${post?.quizTitle}" in a groups ${post?.groups?.map(x => x.name)}.`
                                            } else {
                                                detail = detail + `Posted a post " ${post?.txt}" in a groups ${post?.groups?.map(x => x.name)}.`
                                            }
                                            videos = post?.videos
                                            photos = post?.photos
                                            docs = post?.docs
                                        }
                                        return (
                                            <TableRow onClick={() => {
                                                console.log(post)
                                            }} hover key={createdAt + ""} tabIndex={-1} role="checkbox" >
                                                <TableCell>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <Box>
                                                        <Paragraph paragraph={detail} />
                                                        {videos?.length > 0 &&
                                                            <>
                                                                <Typography sx={{ fontSize: "14px", marginY: "5px", fontWeight: "500" }} color={"primary"}>Videos</Typography>
                                                                <Paragraph paragraph={videos?.map(x => `${image_base_url}${x}`).reduce((a, b) => a + `${a != "" ? "," : ""}` + b, "")} />
                                                            </>
                                                        }
                                                        {photos?.length > 0 &&
                                                            <>
                                                                <Typography sx={{ fontSize: "14px", marginY: "5px", fontWeight: "500" }} color={"primary"}>Images</Typography>
                                                                <Paragraph paragraph={photos?.map(x => `${image_base_url}${x}`).reduce((a, b) => a + `${a != "" ? "," : ""}` + b, "")} />
                                                            </>
                                                        }
                                                        {docs?.length > 0 &&
                                                            <>
                                                                <Typography sx={{ fontSize: "14px", marginY: "5px", fontWeight: "500" }} color={"primary"}>Documents</Typography>
                                                                <Paragraph paragraph={docs?.map(x => `${image_base_url}${x}`).reduce((a, b) => a + `${a != "" ? "," : ""}` + b, "")} />
                                                            </>
                                                        }
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box sx={{ height: "20px", maxWidth: "100px", justifyContent: "center", alignItems: "center", display: "flex", borderRadius: "20px", background: type == "post" ? "#10B0D4" : (type == "comment" ? theme.palette.action.active : theme.palette.success.dark), color: "white" }}>
                                                        {type}
                                                    </Box>
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {moment(createdAt).format("MMM, DD, YYYY hh:mm a")}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
                <MenuItem sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>

        </LocalizationProvider>
    );
}
