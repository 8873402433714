import { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Box, TextField, Typography } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from 'react-redux';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { setMiscData } from 'src/redux/miscdata';
import { setToast } from 'src/redux/toast';
import { useLocation } from 'react-router-dom';
import { getFAQListThunk } from 'src/redux/faq';
import { getMiscDataThunk } from 'src/redux/miscdata';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// components
// mock

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function BlogPage() {
  const [selectedLocal, setSelectedLocal] = useState(localStorage.getItem('pageTab') ?? 0)
  const [selected, setSelected] = useState(0)
  const editor = useRef(null);
  const editor1 = useRef(null);
  const editor2 = useRef(null);
  const editor3 = useRef(null);
  const editor4 = useRef(null);
  const editor5 = useRef(null);

  const [terms, setTerms] = useState("")
  const [policy, setPolicy] = useState("")
  const [about, setAbout] = useState("")
  // const [miscdata, setMiscData] = useState({});
  const faqs = useSelector(state => state.faqs)?.list
  const [landing, setLanding] = useState({
    about: "",
    why: "",
    contact: ""
  })
  const dispatch = useDispatch()
  const writes = useSelector(state => state.navs)?.write
  const user = useSelector(state => state.auth)?.user
  const location = useLocation()
  const [qn, setQn] = useState("")
  const [ans, setAns] = useState("")
  const [content, setContent] = useState('');
  const handleChange = (value) => {
    setTerms(value);
  };

  const getContentData = () => {
    const successData = (miscdata) => {
      // console.log("response", miscdata?.terms);
      // setMiscData(response);
      setTerms(miscdata?.terms)
      setPolicy(miscdata?.policy)
      setAbout(miscdata?.about)
    }
    dispatch(getMiscDataThunk(successData))
  }

  useEffect(() => {
    dispatch(getFAQListThunk())
  }, [location]);

  useEffect(() => {
    let tabNumber = localStorage.getItem('pageTab')
    if (tabNumber) {
      setSelectedLocal(localStorage.getItem('pageTab'))
    } else {
      setSelectedLocal(0)
    }
  }, [selected]);

  useEffect(() => {
    getContentData()
    // if (miscdata?.terms) {
    //   if (miscdata?.landing?.about || miscdata?.landing?.why || miscdata?.landing?.contact) {
    //     setLanding(miscdata?.landing)
    //   }
    // }
  }, [])

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    placeholder: 'Loading...',
    height: 600
  }


  const submit = async () => {
    try {
      dispatch(setLoader(true))
      let res = await hit("/admins/misc", "post", { terms, policy, about, landing })
      if (!res.err) {
        getContentData()
        // dispatch(setMiscData(res.data))
        dispatch(setToast({ show: true, message: "Updated Successfully!", severity: "success" }))

      } else {
        dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))

    }
  }

  const submitFAQ = async () => {
    if (qn != '' && ans != '') {
      try {
        dispatch(setLoader(true))
        let res = await hit("/faqs", "post", { ans, qn })
        if (!res.err) {
          dispatch(getFAQListThunk())
          dispatch(setToast({ show: true, message: "Added Successfully!", severity: "success" }))
          setQn("")
          setAns("")
        } else {
          dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
        }
      } catch (err) {

      } finally {
        dispatch(setLoader(false))

      }
    } else {
      dispatch(setToast({ show: true, message: "Question and Answer can't empty!", severity: "error" }))
    }
  }

  const deleteFAQ = async (id) => {
    try {
      dispatch(setLoader(true))
      let res = await hit("/faqs/" + id, "delete", {})
      if (!res.err) {
        dispatch(getFAQListThunk())
        dispatch(setToast({ show: true, message: "deleted Successfully!", severity: "success" }))
      } else {
        dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))
    }
  };



  return (
    <>
      <Helmet>
        <title> Dashboard: Pages | CultureCode </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" mb={5}>

          <Button color={selectedLocal == 0 ? "primary" : "secondary"} onClick={() => {
            setSelected(0); localStorage.setItem('pageTab', 0)
          }} variant="contained" >
            Terms and services
          </Button>
          <Button onClick={() => {
            setSelected(1); localStorage.setItem('pageTab', 1)
          }} color={selectedLocal == 1 ? "primary" : "secondary"} variant="contained" sx={{ mx: "10px" }}>
            Privacy Policy
          </Button>
          <Button onClick={() => {
            setSelected(2); localStorage.setItem('pageTab', 2)
          }} color={selectedLocal == 2 ? "primary" : "secondary"} variant="contained" >
            About Us
          </Button>
          <Button color={selectedLocal == 3 ? "primary" : "secondary"} sx={{ mx: "10px" }} onClick={() => {
            setSelected(3); localStorage.setItem('pageTab', 3)
          }} variant="contained" >
            FAQ
          </Button>
          {/* <Button onClick={() => {
            setSelected(3)
          }} color={selected == 3 ? "primary" : "secondary"} variant="contained" sx={{ mx: "10px" }} >
            Landing Page
          </Button> */}
        </Stack>
        <div>
          {selectedLocal == 0 &&
            <ReactQuill
              value={terms}
              onChange={handleChange}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'header': 1 }, { 'header': 2 }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],
                  [{ 'indent': '-1' }, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'size': ['small', false, 'large', 'huge'] }],
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  ['clean']
                ],
              }}
            />
            // <JoditEditor
            //   ref={editor}
            //   value={terms}
            //   config={config}
            //   tabIndex={1} // tabIndex of textarea
            //   onBlur={newContent => setTerms(newContent)} // preferred to use only this option to update the content for performance reasons
            // // onChange={newContent => { setTerms(newContent)}}
            // />
          }
          {selectedLocal == 1 &&
            <ReactQuill
              value={policy}
              onChange={(value) => setPolicy(value)}

            />
            // <JoditEditor
            //   ref={editor1}
            //   value={policy}
            //   config={config}
            //   tabIndex={1} // tabIndex of textarea
            //   onContentChange={newContent => setPolicy(newContent)} // preferred to use only this option to update the content for performance reasons
            //   onChange={newContent => { }}
            // />
          }
          {selectedLocal == 2 &&
            // <JoditEditor
            //   ref={editor2}
            //   value={about}
            //   config={config}
            //   tabIndex={1} // tabIndex of textarea
            //   onContentChange={newContent => setAbout(newContent)} // preferred to use only this option to update the content for performance reasons
            //   onChange={newContent => { }}
            // />
            <ReactQuill
              value={about}
              onChange={(value) => setAbout(value)}

            />
          }
          {selectedLocal == 3 && <>
            <Box sx={{
              boxShadow: "1px 10px 55px -12px rgba(0,0,0,0.53)",
              "-webkit-box-shadow": "1px 10px 55px -12px rgba(0,0,0,0.53)",
              "-moz-box-shadow": "1px 10px 55px -12px rgba(0,0,0,0.53)",
              marginTop: "20px",
              borderRadius: "5px",
              padding: "10px",
              maxWidth: "700px",
              display: "flex", alignItems: "center",
              justifyContent: "space-between"
            }}>
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1, marginRight: "5px" }}>
                <TextField value={qn} onChange={e => {
                  setQn(e.target.value)
                }} placeholder='Question' />
                <TextField value={ans} onChange={e => {
                  setAns(e.target.value)
                }} multiline sx={{ marginTop: "5px" }} placeholder="Answer" />
              </Box>
              <Button
                variant="contained"
                onClick={() => {
                  if (user?.role == "admin") {
                    submitFAQ()
                  } else if (user?.role == "staff") {
                    if (writes?.includes("pages")) {
                      submitFAQ()
                    } else {
                      dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))

                    }
                  }

                }}
              >
                Add
              </Button>
            </Box>
            {faqs.map((x, i) => (
              <Box sx={{
                boxShadow: "1px 10px 55px -12px rgba(0,0,0,0.53)",
                "-webkit-box-shadow": "1px 10px 55px -12px rgba(0,0,0,0.53)",
                "-moz-box-shadow": "1px 10px 55px -12px rgba(0,0,0,0.53)",
                marginTop: "20px",
                borderRadius: "5px",
                padding: "10px",
                maxWidth: "700px",
                display: "flex", alignItems: "center"
              }}>
                <Typography>{i + 1}) </Typography>
                <Box sx={{ flex: 1, marginLeft: "10px" }} >
                  <Typography sx={{ fontSize: "1.5em", fontWeight: "600" }}>{x?.qn}</Typography>
                  <Typography>{x?.ans}</Typography>
                </Box>
                <Button onClick={() => {
                  // 
                  if (user?.role == "admin") {
                    deleteFAQ(x?._id)
                  } else if (user?.role == "staff") {
                    if (writes?.includes("pages")) {
                      deleteFAQ(x?._id)
                    } else {
                      dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))

                    }
                  }
                }} variant='contained' color='error'>
                  Delete
                </Button>
              </Box>
            ))}
          </>
          }
        </div>
        {selectedLocal != 3 && <Box display="flex" justifyContent={"center"} mt={4}>
          <Button onClick={() => {
            if (user?.role == "admin") {
              submit()
            } else if (user?.role == "staff") {
              if (writes?.includes("pages")) {
                submit()
              } else {
                dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))

              }
            }
          }} variant='contained' >
            Submit
          </Button>
        </Box>}
      </Container>
    </>
  );
}
