import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, Box, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Iconify from '../iconify';
import { setToast } from 'src/redux/toast';


export default function UserPass({ title, description, open, setOpen, onSubmit, onCancel, selected }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch()
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [dashboard, setDashboard] = React.useState({
    read: true,
    write: true
  })
  const [users, setUsers] = React.useState({
    read: false,
    write: false
  })
  const [teams, setTeams] = React.useState({
    read: false,
    write: false
  })
  const [subs, setSubs] = React.useState({
    read: false,
    write: false
  })
  const [pages, setPages] = React.useState({
    read: false,
    write: false
  })
  const [settings, setSettings] = React.useState({
    read: false,
    write: false
  })
  const [supports, setSupports] = React.useState({
    read: false,
    write: false
  })
  React.useEffect(() => {
    if (selected) {
      setEmail(selected?.email)
      setPassword(selected?.accessPass)
      setDashboard(selected?.accessLevel?.dashboard)
      setUsers(selected?.accessLevel?.users)
      setTeams(selected?.accessLevel?.teams)
      setSubs(selected?.accessLevel?.subs)
      setPages(selected?.accessLevel?.pages)
      setSupports(selected?.accessLevel?.supports)
    } else {
      setEmail("")
      setPassword("")
      setDashboard({
        read: true,
        write: true
      })
      setUsers({
        read: false,
        write: false
      })
      setTeams({
        read: false,
        write: false
      })
      setSubs({
        read: false,
        write: false
      })
      setPages({
        read: false,
        write: false
      })
      setSupports({
        read: false,
        write: false
      })
    }
  }, [open, selected])
  return (
    <Dialog
      open={open}
      onClose={() => { setOpen(false) }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        <Box sx={{ marginTop: "20px" }}>
          <TextField value={email} onChange={e => {
            setEmail(e.target.value)
          }} name="email" label="Email address" />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => {
              setPassword(e.target.value)
            }}
            sx={{ marginLeft: "10px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          {/* <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <Link color={"ButtonText"} variant="subtitle2" underline="hover">
              Dashboard
            </Link>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setDashboard({ ...dashboard, read: e.target.checked })
              }} checked={dashboard.read} name="dash" />
              <Typography>View</Typography>
            </Box>
          </Box> */}
          <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <div className="" style={{ width: '17%' }}>
              <Link color={"ButtonText"} variant="subtitle2" underline="hover">
                Users
              </Link>
            </div>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setUsers({ ...users, read: e.target.checked })
              }} checked={users.read} name="dash" />
              <Typography>View</Typography>
              <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setUsers({ read: e.target.checked ?? users.read, write: e.target.checked })
              }} checked={users.write} name="dash" />
              <Typography>Edit</Typography>
            </Box>
          </Box>
          <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <div className="" style={{ width: '17%' }}>
              <Link color={"ButtonText"} variant="subtitle2" underline="hover">
                Team
              </Link>
            </div>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setTeams({ ...teams, read: e.target.checked })
              }} checked={teams.read} name="dash" />
              <Typography>View</Typography>
              <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setTeams({ read: e.target.checked ?? teams.read, write: e.target.checked })
              }} checked={teams.write} name="dash" />
              <Typography>Edit</Typography>
            </Box>
          </Box>
          {/* <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <Link color={"ButtonText"} variant="subtitle2" underline="hover">
              Subscriptions
            </Link>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setSubs({ ...subs, read: e.target.checked })
              }} checked={subs.read} name="dash" />
              <Typography>View</Typography>
              <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setSubs({ read:e.target.checked??subs.read, write: e.target.checked })
              }} checked={subs.write} name="dash" />
              <Typography>Edit</Typography>
            </Box>
          </Box> */}
          <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <div className="" style={{ width: '17%' }}>
              <Link color={"ButtonText"} variant="subtitle2" underline="hover">
                Pages
              </Link>
            </div>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setPages({ ...pages, read: e.target.checked })
              }} checked={pages.read} name="dash" />
              <Typography>View</Typography>
              <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setPages({ read: e.target.checked ?? pages.read, write: e.target.checked })
              }} checked={pages.write} name="dash" />
              <Typography>Edit</Typography>
            </Box>
          </Box>
          <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <div className="" style={{ width: '17%' }}>
              <Link color={"ButtonText"} variant="subtitle2" underline="hover">
                Contact Us
              </Link>
            </div>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setSupports({ ...supports, read: e.target.checked })
              }} checked={supports.read} name="dash" />
              <Typography>View</Typography>
              <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setSupports({ read: e.target.checked ?? supports.read, write: e.target.checked })
              }} checked={supports.write} name="dash" />
              <Typography>Edit</Typography>
            </Box>
          </Box>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => {
          if (email?.trim() == "" || password?.trim() == "") {
            dispatch(setToast({ message: "Email and Password required*", severity: "error", show: true }))
            return
          }
          if (email != '') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValid = emailRegex.test(email);
            if (!isValid) {
              dispatch(setToast({ message: "Email is not Valid", severity: "error", show: true }))
              return
            }
          }

          if (dashboard?.read == false && users?.read == false && teams?.read == false && subs?.read == false && pages?.read == false && supports?.read == false) {
            return
          }
          onSubmit({
            email,
            password,
            accessLevel: {
              dashboard,
              users,
              teams,
              subs,
              settings,
              pages,
              supports
            }
          }, selected)
        }} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}