import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import { image_base_url } from 'src/services/endpoints';
import moment from 'moment'
import AskDialog from 'src/components/ask';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { setToast } from 'src/redux/toast';
import { getAllUsers } from 'src/redux/users';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDataThunk } from 'src/redux/auth';
import { getTeamsListThunk } from 'src/redux/teams';
import { getScoresListThunk } from 'src/redux/scores';
import RankingsModal from 'src/components/ranking';
import { CSVLink, CSVDownload } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { getPostListThunk } from 'src/redux/posts';
import FilterDate from 'src/components/FilterDate';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'Total Admins', label: 'Total Admins', alignRight: false },
  { id: 'Total Members', label: "Total Members", alignRight: false },
  { id: 'Created_y', label: "Created By", alignRight: false },
  { id: 'Scoring Opportunities(%)', label: "Scoring Opportunities(%)", alignRight: false },
  { id: 'Avg Scores', label: "Avg Scores", alignRight: false },
  { id: 'action', label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => (_user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 || _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1));
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);
  const user = useSelector(state => state.auth)?.user
  const navs = useSelector(state => state.navs)?.write

  const [page, setPage] = useState(0);
  const dispatch = useDispatch()
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [selectedTId, setSelectedTId] = useState(null);

  const [orderBy, setOrderBy] = useState('firstName');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const users = useSelector(state => state.users)?.list
  const teams = useSelector(state => state.teams)?.list
  const scores = useSelector(state => state.scores)?.list
  const posts = useSelector(state => state.posts)?.list

  const [allTeams, setAllTeams] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const filter = useSelector(state => state.filtert)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [selectedData, setSelectedData] = useState({
    status: 0,
    _id: null
  })

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const location = useLocation()
  useEffect(() => {
    dispatch(getTeamsListThunk())
    dispatch(getScoresListThunk())
    dispatch(getPostListThunk())
  }, [location])
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allTeams?.map((n) => n?.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const deleteUser = async () => {
    try {
      dispatch(setLoader(true))
      let res = await hit("admins/users/" + selectedData?._id, "delete",)
      if (!res.err) {
        dispatch(getAllUsers())
      } else {
        dispatch(setToast({ show: true, message: res.msg, severity: "error" }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))

    }
  }

  useEffect(() => {
    let ft = []
    let ts = teams.filter(x => x.type == "group")
    for (let t of ts) {
      let fscores = scores.filter(x => x.groups?.includes(String(t?._id)))
      let totalscores = fscores.map(x => x.point).reduce((a, b) => a + b, 0)
      let avg = (totalscores / ((t?.members?.length + t?.ableToScores?.length - t?.admins?.length) == 0 ? 1 : (t?.members?.length + t?.ableToScores?.length - t?.admins?.length)))
      if (filter?.min > 0) {
        if (avg > filter?.min) {
          ft.push({ ...t, score: avg })
        }
      } else {
        ft.push({ ...t, score: avg })
      }
    }
    if (user?.role == "staff" || user?.role == "admin") {
      ft = ft.filter(x => x.type == "group")
    } else {
      ft = ft.filter(x => (x.type == "group" && x?.admins?.map(z => z?._id)?.includes(user?._id)))
    }
    let newCSV = []
    newCSV.push(['Sno', 'Name', 'Total Admins', 'Total Members', 'Created By', "Avg Scores", "Created At"])
    let i = 1
    for (let team of ft) {
      if (!team) {
        continue
      }
      const { _id, name, admins, members, createdBy, createdAt, score } = team;
      newCSV.push([i, name, admins.length, members.length, `${createdBy?.firstName} ${createdBy.lastName} (${createdBy.email})`, score, moment(createdAt).format("DD/MM/YYYY")])
      i++
    }
    setCsvData(newCSV)
    setAllTeams(ft)
  }, [teams, filter, scores, user])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const startSerialNumber = page * rowsPerPage + 1;

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allTeams?.length) : 0;

  const filteredUsers = applySortFilter(allTeams, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const navigate = useNavigate()

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";


  var convert = function (srcNum, scrDict, targetDict) {
    //Like 9f -> JP
    var targetNum = "";
    for (var idx in srcNum) {
      var srcDictIdx = scrDict.search(srcNum[idx]);
      targetNum += targetDict[srcDictIdx]
    }
    return targetNum;
  }



  const exportToCSV = (members, fileName, teamid, dateRanges, type) => {
    console.log("**************Export Csv Start*********");
    console.log("teamid >>> ", teamid);
    console.log("**************Export Csv End*********");
    let dupScores = scores?.filter(x => {
      // Check if x.groups includes teamid
      if (!(x.groups?.some(group => group == teamid))) {
        return false;
      }
      if (type == "date") {
        let firstDate = dateRanges[0]
        let secondDate = dateRanges[1]
        if (x?.type == 3) {
          if (moment(firstDate).toDate() <= moment(x?.updatedAt).toDate() && moment(secondDate) >= moment(x?.updatedAt).toDate()) {
            return true
          }
          return false
        } else {
          if (moment(firstDate).toDate() <= moment(x?.createdAt).toDate() && moment(secondDate) >= moment(x?.createdAt).toDate()) {
            return true
          }
          return false
        }
      }
      return true
    })
    let dupPosts = posts.filter(x => (x.groups?.map(g => g?._id)?.includes(teamid))).filter(x => {
      if (type == "date") {
        let firstDate = dateRanges[0]
        let secondDate = dateRanges[1]
        if (moment(firstDate).toDate() <= moment(x?.scoreStartTime).toDate() && moment(secondDate) >= moment(x?.scoreStartTime).toDate()) {
          return true
        }
        return false
      }
      return true
    })

    console.log("dupScores >> ",dupScores.length)
    console.log("dupPosts >> ",dupPosts.length)

    let manualsCats = dupScores?.filter(x => x.type == 4 && x?.manualId?._id).map(x => x?.manualId?.category)
    let categorys = [...new Set([...dupPosts.filter(x => (x.groups?.map(g => g?._id)?.includes(teamid) && x.category != "")).map(x => x.category), ...manualsCats, "Quiz"])]
    let totalScoringPostIndiPoints = dupPosts.filter(x => x.groups?.map(g => g?._id)?.includes(teamid))
    let tsPoints = 0
    for (let p of totalScoringPostIndiPoints) {
      if (p?.scoreBy?.includes("likes")) {
        tsPoints += p?.scorePoint
      }
      if (p?.scoreBy?.includes("verification")) {
        tsPoints += (p?.scorePoint1 * p?.scoreCount)
      }
      if (p?.scoreBy?.includes("geo")) {
        tsPoints += p?.scorePoint2
      }
      if (p?.postType1 == "quiz") {
        for (let q of p?.quizData) {
          tsPoints += q?.point
        }
      }
    }

    let cs = []
    for (let i of categorys) {
      let postsl = dupPosts.filter(x => (x.category == i || (x?.postType1 == "quiz" && i == "Quiz"))).length
      cs.push(i)
      for (let j = 0; j < postsl; j++) {
        cs.push("")
      }
    }
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [["Name (Email)", "Gender", "Grade", "Scoring Opportunities(%)", "Average Score", "Total Points", ...cs]], { origin: "A1" });
    let merge = []
    let prevCol = 6
    for (let i = 0; i < categorys.length; i++) {
      let postsl = dupPosts.filter(x => (x.category == categorys[i] || (x?.postType1 == "quiz" && categorys[i] == "Quiz"))).length
      merge.push({
        s: { r: 0, c: prevCol },
        e: { r: 0, c: prevCol + postsl },
      })
      prevCol = prevCol + postsl + 1
    }
    ws["!merges"] = merge
    let A2 = ["", "", "", "", "", ""]
    for (let i of categorys) {
      let ps = dupPosts.filter(x => x.category == i || (x?.postType1 == "quiz" && i == "Quiz")).map(x => {
        if (x?.postType1 == "quiz") {
          return x?.quizTitle + " (Quiz)"
        }
        return x?.txt
      })
      for (let p of ps) {
        A2.push(p)
      }
      A2.push("Manual Points")
    }
    ws['!rows'] = A2
    const cellRef = XLSX.utils.encode_cell({ c: 0, r: 1 });
    XLSX.utils.sheet_add_aoa(ws, [A2], { origin: cellRef });
    let i = 2
    for (let m of members) {
      const cellRef = XLSX.utils.encode_cell({ c: 0, r: i });
      let v = []
      v.push(`${m?.firstName} ${m?.lastName} (${m?.email})`)
      v.push(m?.gender ?? "male")
      v.push(m?.grade)

      let pScores = []
      let totalScores = 0
      for (let ca of categorys) {
        let ps = dupPosts.filter(x => (x.category == ca || (x?.postType1 == "quiz" && ca == "Quiz")))
        for (let p of ps) {
          let pScore = 0
          let uscores = dupScores.filter(x => (x?.user?._id == m?._id && x?.postId?._id == p._id && x?.groups?.includes(teamid)))
          for (let score of uscores) {
            pScore = pScore + score.point
          }
          totalScores = totalScores + pScore
          pScores.push(pScore)
        }
        let scs = dupScores.filter(x => x?.manualId?.category == ca && x?.user?._id == m?._id)?.filter(x => x?.groups?.includes(teamid))?.map(x => x.point)?.reduce((a, b) => a + b, 0)
        pScores.push(scs)
      }
      //  let qScores=dupScores.filter(x => (x?.user?._id == m?._id&&x?.type==5&&x?.postId?.postType1=="normal"&&x?.groups?.includes(teamid)))?.map(x => x.point)?.reduce((a, b) => a + b, 0)
      let unscores = Math.round((dupScores.filter(x => x?.user?._id == m?._id && x?.type == 4 && x?.groups?.includes(teamid)).map(x => x.point).reduce((a, b) => a + b, 0)))
      v.push((Number.isNaN(Math.round((totalScores + unscores) * 100 / ((tsPoints + unscores) ?? 1))) ? "0" : Math.round((totalScores + unscores) * 100 / ((tsPoints + unscores) ?? 1))) + "%")
      v.push(Math.round((tsPoints + unscores) / ((totalScores + unscores) ?? 1)))
      v.push(totalScores + unscores)
      for (let ps of pScores) {
        v.push(ps)
      }
      // v.push(qScores)
      XLSX.utils.sheet_add_aoa(ws, [v], { origin: cellRef });
      i++
    }

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const getAllTeamData = async (teamId, dateRanges, type) => {
    console.log("************************getAllTeamData Start***************");
    console.log("type", type);
    console.log("teamId", teamId);
    console.log("dateRanges", dateRanges);
    console.log("************************getAllTeamData End***************");
    try {
      let team = teams.find(x => x._id == teamId)
      exportToCSV(team.members, Date.now(), teamId, dateRanges, type)

    } catch (err) {

    } finally {

    }
  }


  return (
    <>
      <Helmet>
        <title> Teams | CultureCode </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Teams
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
          <CSVLink style={{ color: "#0DB1D4" }} filename={"teams.csv"} data={csvData}>Download CSV Data</CSVLink>
        </Stack>

        <Card>
          <UserListToolbar placeholder={"Search Teams..."} typeFilter="teams" numSelected={selected.length} show={true} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={allTeams.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { _id, name, admins, members, ableToScores, createdBy, profilePic } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;
                    let fscores = scores.filter(x => x.groups?.includes(String(_id)))
                    let totalscores = fscores.map(x => x.point).reduce((a, b) => a + b, 0)
                    let tsp = posts.filter(x => x?.groups?.map(x => x?._id)?.includes(_id))
                    let tsPoints = 0
                    for (let p of tsp) {
                      if (p?.scoreBy?.includes("likes")) {
                        tsPoints += Number(p?.scorePoint)
                      }
                      if (p?.scoreBy?.includes("verification")) {
                        tsPoints += (Number(p?.scorePoint1) * Number(p?.scoreCount))
                      }
                      if (p?.scoreBy?.includes("geo")) {
                        tsPoints += Number(p?.scorePoint2)
                      }
                    }
                    tsPoints = tsPoints * (members.length)
                    // console.log(tsPoints)
                    let uScores = scores.filter(x => x.groups?.includes(String(_id) && x?.type == 4)).map(x => x?.point).reduce((a, b) => a + b, 0)
                    tsPoints += uScores
                    let ops = Math.round((totalscores / (tsPoints < 0 ? 1 : tsPoints)) * 100) > 100 ? 100 : Math.round((totalscores / (tsPoints < 0 ? 1 : tsPoints)) * 100)
                    let avg = (totalscores / ((members.length + ableToScores.length - admins.length) == 0 ? 1 : (members.length + ableToScores.length - admins.length)))
                    ops = Number.isNaN(ops) ? "0" : ops
                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox" sx={{ paddingLeft: 3 }}>
                          {/* <Checkbox checked={selectedUser} onChange={(event) => {
                            handleClick(event, _id)
                          }
                          } /> */}
                          {startSerialNumber + index}
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={image_base_url + profilePic} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{admins?.length}</TableCell>
                        <TableCell align="left">{members.length}</TableCell>
                        <TableCell align="left">{createdBy?.firstName}</TableCell>
                        {/* <TableCell align="left">{address}</TableCell> */}
                        <TableCell align="left">
                          {ops} %
                        </TableCell>
                        <TableCell align="left">
                          {Math.round(avg)}
                        </TableCell>
                        <TableCell align="right" sx={{ display: "flex", flexDirection: "column-reverse", }}>
                          <Button onClick={() => {
                            if (user?.role == "admin" || user?.role == "staff") {
                              if (user?.role == "admin") {
                                setSelectedGroup(_id)
                                setOpen2(true)
                              } else {
                                if (navs.includes("teams")) {
                                  setSelectedGroup(_id)
                                  setOpen2(true)
                                } else {
                                  dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))
                                }
                              }
                            } else {
                              setSelectedGroup(_id)
                              setOpen2(true)
                            }

                          }} sx={{ marginY: "5px" }} color={'info'} variant="contained" >Rankings</Button>
                          <Button onClick={() => {
                            if (user?.role == "admin" || user?.role == "staff") {
                              if (user?.role == "admin") {
                                // getAllTeamData(_id)
                                setSelectedTId(_id)
                                setOpen1(true)
                              } else {
                                if (navs.includes("teams")) {
                                  // getAllTeamData(_id)
                                  setSelectedTId(_id)
                                  setOpen1(true)

                                } else {
                                  dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))
                                }
                              }
                            } else {
                              setSelectedTId(_id)
                              setOpen1(true)

                              // getAllTeamData(_id)

                            }

                          }} sx={{ marginY: "5px" }} color={'info'} variant="contained" >Export Sheet</Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={allTeams.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      {/* <AskDialog open={open1} title="User Activate/Deactivate" description={`Do you want to ${selectedData.status == 0 ? "activate" : "deactivate"} this user?`} setOpen={setOpen1} onCancel={() => {
        setOpen1(false)
      }} onOk={() => {
        setOpen1(false)
        deactivate()
      }} /> */}
      {
        open1 &&
        <FilterDate
          open={open1}
          setOpen={setOpen1}
          onSubmit={(value) => {
            if (value) {
              if (value[0] && value[1]) {
                console.log("value3", value[0], "value[1]", value[1]);
                if (moment(new Date(value[0])).format("YYYY-MM-DD HH:mm") != moment(new Date(value[1])).format("YYYY-MM-DD HH:mm")) {
                  getAllTeamData(selectedTId, value, "date")
                  setOpen1(false)
                } else {
                  dispatch(setToast({ show: true, message: "Both date should be different!", severity: "error" }))
                }
              }
            }

          }}
          onAll={() => {
            getAllTeamData(selectedTId, [], "all")
            setOpen1(false)
          }}
        />
      }
      <RankingsModal selectedGroupId={selectedGroup} open={open2} title="Rankings" description={`Do you want to delete this user?`} setOpen={setOpen2} onCancel={() => {
        setOpen2(false)
      }} onOk={() => {
        setOpen2(false)
        deleteUser()
        // deactivate()
      }} />
    </>
  );
}
