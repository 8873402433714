import { Circles } from 'react-loader-spinner'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

export default function Loader() {
    const loader = useSelector(state => state.loader)?.show
    return (
        <>
            {loader ? (
                <Box
                    sx={{
                        height: "100vh",
                        display: "flex",
                        background: "#0007",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vw",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        zIndex: 10000
                    }}
                >
                    <div style={{
                        height: "100%", display: "flex", justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <img src='assets/loader1.gif' style={{ height: "80px" }} alt="loading" />
                    </div>
                </Box>
            ) : null}

        </>
    )
}