import { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Box, TextField, Typography, Badge } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from 'react-redux';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { getMiscDataThunk, setMiscData } from 'src/redux/miscdata';
import { setToast } from 'src/redux/toast';
import { useLocation } from 'react-router-dom';
import { getFAQListThunk } from 'src/redux/faq';
import { image_base_url } from 'src/services/endpoints';

// components
// mock

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function BirthdayPage() {

    const [wishGif, setWishGif] = useState("")
    const [wishText, setWishText] = useState("")
    const miscdata = useSelector(state => state.miscdata)?.data
    const dispatch = useDispatch()
    const writes = useSelector(state => state.navs)?.write
    const user = useSelector(state => state.auth)?.user
    const location = useLocation()


    useEffect(() => {
        dispatch(getMiscDataThunk())
    }, [location])
    useEffect(() => {
        if (miscdata?.wishGif) {
            setWishGif(miscdata?.wishGif)
        }
        if (miscdata?.wishText) {
            setWishText(miscdata?.wishText)
        }
    }, [miscdata])


    const submit = async (data) => {
        try {
            dispatch(setLoader(true))
            let res = await hit("/admins/misc", "post", data)
            if (!res.err) {
                dispatch(setMiscData(res.data))
                dispatch(setToast({ show: true, message: "Updated Successfully!", severity: "success" }))
            } else {
                dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    const handleFile = async (e) => {
        try {
            if (e.target.files[0]) {
                dispatch(setLoader(true))
                const formdata = new FormData()
                formdata.append("file", e.target.files[0])
                let x = await hit("/uploads", "post", formdata)
                console.log(x)
                if (!x.err) {

                    if (x?.data?.length > 0) {
                        submit({ wishGif: x?.data[0] })
                    } else {
                        dispatch(setLoader(false))
                    }
                } else {
                    dispatch(setLoader(false))

                }
            }
        } catch (err) {
            console.log(err)
        }


    }
    return (
        <>
            <Helmet>
                <title> BirthDay Gif: Pages | CultureCode </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Birthday Gif Set
                    </Typography>
                </Stack>
                <Typography>Change To Custom Gif</Typography>
                <div style={{ position: "relative" }}>
                    <Badge
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        overlap="circle"
                        badgeContent={"+"}
                        color="primary"
                    >
                        <img src={image_base_url + wishGif} style={{ height: "200px", width: "200px", marginTop: "10px" }} />
                    </Badge>
                    <input type="file" id="file-selector" accept=".gif" onChange={handleFile} style={{ position: "absolute", height: "100%", width: "100%", top: 0, left: 0, opacity: 0, zIndex: 111 }}></input>
                </div>
                <Typography mt={"20px"} marginBottom="10px">Type Custom Birthday Message</Typography>
                <Stack flexDirection={"row"} alignItems="center">
                    <TextField value={wishText} onChange={e => {
                        setWishText(e.target.value)
                    }} placeholder="Birthday  Wish Text" />
                    <Box display="flex" mx={4}>
                        <Button onClick={() => {
                            if (user?.role == "admin") {
                                submit({ wishText })
                            } else if (user?.role == "staff") {
                                if (writes?.includes("pages")) {
                                    submit({ wishText })
                                } else {
                                    dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))
                                }
                            }
                        }} variant='contained' >
                            Update
                        </Button>
                    </Box>
                </Stack>
            </Container>
        </>
    );
}
