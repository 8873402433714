import {createSlice} from '@reduxjs/toolkit'

const initialState={
    max:0,
    min:0
}

const filterSlice=createSlice({
    name:"filtert",
    initialState,
    reducers:{
        setFilterTData:(state,action)=>{
            return action.payload
        },
        clearFilterTData:(state,action)=>{
            return initialState
        }
    }
})

export const {clearFilterTData,setFilterTData}=filterSlice.actions

export default filterSlice.reducer