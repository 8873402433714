import {createSlice} from '@reduxjs/toolkit'

const initialState={
    roles:[],
    types:[],
    grades:[],
    activityTypes:[],
    dateRange:[]
}

const filterSlice=createSlice({
    name:"filterData",
    initialState,
    reducers:{
        setFilterData:(state,action)=>{
            return action.payload
        },
        clearFilterData:(state,action)=>{
            return initialState
        }
    }
})

export const {setFilterData,clearFilterData}=filterSlice.actions

export default filterSlice.reducer