import { configureStore } from '@reduxjs/toolkit'

import auth from './auth'
import orders from './orders'
import users from './users'
import miscdata from './miscdata'
import toast from './toast'
import loader from './loader'
import supports from './support'
import navs from './navs'
import filter from './filter'
import filtert from './filtert'
import faqs from './faq'
import scores from './scores'
import teams from './teams'
import posts from './posts'
const store = configureStore({
    reducer: {
        auth,
        orders,
        users,
        miscdata,
        toast,
        loader,
        supports,
        navs,
        filter,
        scores,
        teams,
        filtert,
        faqs,
        posts
    }
})

export { store }